var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalEditarCuenta",attrs:{"titulo":"Editar caja fuerte","tamano":"modal-lg","no-aceptar":"","adicional":"Editar"},on:{"adicional":_vm.editarCaja}},[_c('ValidationObserver',{ref:"validacion"},[_c('div',{staticClass:"row mx-0 justify-center"},[_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"rules":"required|max:100","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"f-12 text-general pl-3 f-500"},[_vm._v("Nombre de la caja")]),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Nombre"},model:{value:(_vm.model.nombre),callback:function ($$v) {_vm.$set(_vm.model, "nombre", $$v)},expression:"model.nombre"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required|numeric|max_value:65535","name":"verificación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"f-12 text-general pl-3 f-500  mt-4"},[_vm._v("Tiempo máximo de verificación de saldo\" en horas")]),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Verificación"},model:{value:(_vm.model.verificacion_saldo),callback:function ($$v) {_vm.$set(_vm.model, "verificacion_saldo", $$v)},expression:"model.verificacion_saldo"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required|max:500","name":"descripción"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"f-12 text-general pl-3 f-500"},[_vm._v("Descripción")]),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Descripción de la caja","type":"textarea","rows":5},model:{value:(_vm.model.descripcion),callback:function ($$v) {_vm.$set(_vm.model, "descripcion", $$v)},expression:"model.descripcion"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-6 mt"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"moneda"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"f-12 text-general f-500 pl-3"},[_vm._v("Seleccionar moneda")]),_c('el-select',{staticClass:"w-100",attrs:{"filterable":""},model:{value:(_vm.model.idm_moneda),callback:function ($$v) {_vm.$set(_vm.model, "idm_moneda", $$v)},expression:"model.idm_moneda"}},_vm._l((_vm.monedas),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.sigla,"value":item.id}})}),1),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('p',{staticClass:"f-12 text-general f-500 pl-3 mt-4"},[_vm._v("Seleccionar cedis")]),_c('el-select',{staticClass:"w-100",attrs:{"filterable":""},on:{"change":_vm.addCedis},model:{value:(_vm.id_cedis),callback:function ($$v) {_vm.id_cedis=$$v},expression:"id_cedis"}},_vm._l((_vm.selectCedis),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id,"disabled":item.disabled}})}),1),(_vm.validarCedis)?_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v("Selecciona al menos un cedis.")]):_vm._e(),_vm._l((_vm.cedisAgregados),function(c,key){return _c('div',{key:key,staticClass:"row mx-0 bg-light-f5 border align-items-center pl-2 mt-2 br-5 f-14",staticStyle:{"height":"32px"}},[_vm._v(" "+_vm._s(c.nombre)+" "),_c('i',{staticClass:"icon-cancel ml-auto mr-2 text-general f-16 cr-pointer",on:{"click":function($event){return _vm.removerCedis(c.id)}}})])})],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }